body {
  background-color: #f0f3fc !important;
}

.map-container {
  width: 100%;
  height: 100%;
  position: relative;
}
input::-ms-reveal,
input::-ms-clear {
  display: none;
}

.dropzone .file-name,
.dropzone-data-input .file-name {
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.dropzone {
  font-family: 'Nunito', 'Helvetica', 'Arial', sans-serif;
  flex: 1;
  display: flex;
  font-size: 14px;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 93%;
  padding: 15px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
}

.dropzone-uploaded {
  font-family: 'Nunito', 'Helvetica', 'Arial', sans-serif;
  flex: 1;
  display: flex;
  font-size: 14px;
  flex-direction: row;
  justify-content: flex-start;
  cursor: pointer !important;
  align-items: center;
  width: 93%;
  padding: 15px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
}

.input-ktree-container {
  margin-right: 0px;
}

.dropzone-data-input {
  font-family: 'Nunito', 'Helvetica', 'Arial', sans-serif;
  flex: 1;
  display: flex;
  font-size: 14px;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 93%;
  padding: 15px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #c7d7ec;
  border-style: dashed;
  background-color: #fbfdff;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
}

.custom-cube-tree-data-grouping-cell,
.custom-cube-tree-data-header {
  background-color: #edf2ff;
}

.cube-entry-cell-changed {
  background-color: #f2b7b7 !important;
}

.font-bold {
  font-weight: bold !important;
}

.tree-dropdown-parent-option {
  background-color: #f2b7b7 !important;
}

.progressBar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 1.6px;
  overflow: hidden;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0);
}

.progressBarInner {
  position: absolute;
  left: -50%;
  width: 90%;
  height: 100%;
  background: linear-gradient(to right, transparent, #3f50b5, transparent);
  animation: loading 1.8s cubic-bezier(0.22, 0.61, 1, 1) infinite;
}

@keyframes loading {
  from {
    left: -100%;
  }
  to {
    left: 150%;
  }
}

.text-area {
  font-family: 'Nunito', 'Helvetica', 'Arial', sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 12px;
  border-radius: 12px 12px 0 12px;
  color: #131313;
  background: #fff;
  border: 1px solid #daecff;
  box-shadow: 0px 2px 2px #f6f8fa;
}

.text-area:focus {
  border-color: #9bb6d0;
  box-shadow: 0 0 0 3px #daecff;
}

.text-area:focus-visible {
  outline: none;
}

.error-background {
  color: #a04b4b !important;
}
.dot-loading::after {
  animation: dots 2.5s linear infinite;
  content: '';
}

@keyframes dots {
  0%,
  20% {
    content: '.';
  }
  40% {
    content: '..';
  }
  60% {
    content: '...';
  }
  90%,
  100% {
    content: '';
  }
}
@keyframes l {
  to {
    clip-path: inset(0 -1ch 0 0);
  }
}
.blinking-element {
  animation: blink-slide 2s linear infinite;
}

@keyframes blink-slide {
  0%,
  100% {
    color: #999999;
  }
  25% {
    color: #cccccc;
  }
  50% {
    color: #999999;
  }
  75% {
    color: #cccccc;
  }
}

/* For Insight View Cell Code Rules */

.insight-view-cell-red {
  background-color: #ef6d6d !important;
}
.insight-view-cell-orange {
  background-color: #eda828 !important;
}
.insight-view-cell-yellow {
  background-color: #f5f55e !important;
}
.insight-view-cell-green {
  background-color: #6ecb6e !important;
}
.insight-view-cell-blue {
  background-color: #4466d8 !important;
}
.insight-view-cell-indigo {
  background-color: #a16bc9 !important;
}
.insight-view-cell-violet {
  background-color: #e18be1 !important;
}
.insight-view-cell-pink {
  background-color: #fbd6dc !important;
}
.insight-view-cell-brown {
  background-color: #ab6464c4 !important;
}

.insight-view-cell-gray {
  background-color: #afaeae !important;
}

.insight-view-cell-unsorted {
  opacity: 0.5;
}
